import SvgIcon from "@mui/material/SvgIcon";

export default function OptionSell() {
  return (
    <SvgIcon viewBox="0 0 70 31" sx={{ width: "46px", height: "26px" }}>
      <svg
        width="80"
        height="31"
        viewBox="0 0 80 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(10, 0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.914 29.6242C47.746 29.6242 54.0897 23.2965 54.0897 15.4977C54.0897 7.69883 47.746 1.37109 39.914 1.37109C32.0819 1.37109 25.7383 7.69883 25.7383 15.4977C25.7383 23.2965 32.0819 29.6242 39.914 29.6242ZM39.914 30.6242C48.2953 30.6242 55.0897 23.8518 55.0897 15.4977C55.0897 7.14349 48.2953 0.371094 39.914 0.371094C31.5327 0.371094 24.7383 7.14349 24.7383 15.4977C24.7383 23.8518 31.5327 30.6242 39.914 30.6242Z"
            fill="#212121"
          />
          <path
            d="M47.7841 20.4143C47.7036 20.6082 47.5495 20.7623 47.3556 20.8428C47.2602 20.8834 47.1578 20.905 47.0541 20.9062H43.087C42.8766 20.9062 42.6748 20.8227 42.526 20.6739C42.3772 20.5251 42.2936 20.3233 42.2936 20.1128C42.2936 19.9024 42.3772 19.7006 42.526 19.5518C42.6748 19.403 42.8766 19.3194 43.087 19.3194H45.142L40.7068 14.8842L38.0965 17.5025C38.0227 17.5769 37.9349 17.6359 37.8383 17.6762C37.7416 17.7164 37.6379 17.7372 37.5331 17.7372C37.4284 17.7372 37.3247 17.7164 37.228 17.6762C37.1313 17.6359 37.0436 17.5769 36.9698 17.5025L32.2093 12.742C32.1349 12.6682 32.0759 12.5805 32.0356 12.4838C31.9953 12.3871 31.9746 12.2834 31.9746 12.1787C31.9746 12.0739 31.9953 11.9702 32.0356 11.8735C32.0759 11.7769 32.1349 11.6891 32.2093 11.6153C32.2831 11.541 32.3708 11.482 32.4675 11.4417C32.5642 11.4014 32.6679 11.3807 32.7726 11.3807C32.8774 11.3807 32.9811 11.4014 33.0778 11.4417C33.1744 11.482 33.2622 11.541 33.336 11.6153L37.5331 15.8205L40.1435 13.2022C40.2172 13.1278 40.305 13.0688 40.4017 13.0285C40.4983 12.9882 40.6021 12.9675 40.7068 12.9675C40.8115 12.9675 40.9152 12.9882 41.0119 13.0285C41.1086 13.0688 41.1964 13.1278 41.2701 13.2022L46.2607 18.2007V16.1458C46.2607 15.9353 46.3443 15.7335 46.4931 15.5847C46.6419 15.4359 46.8437 15.3523 47.0541 15.3523C47.2645 15.3523 47.4664 15.4359 47.6152 15.5847C47.7639 15.7335 47.8475 15.9353 47.8475 16.1458V20.1128C47.8463 20.2165 47.8247 20.3189 47.7841 20.4143Z"
            fill="white"
          />
        </g>
        <circle cx="15.2656" cy="15.498" r="15.127" fill="#100A14" />
        <g clipPath="url(#clip0_2833_30158)">
          <path
            d="M11.9544 6.62305H9.74674V8.67303H7.53906V20.9729H9.74674V23.0229H11.9544V20.9729H14.1621V8.67303H11.9544V6.62305ZM22.9928 10.723H20.7851V6.62305H18.5774V10.723H16.3698V17.898H18.5774V23.0229H20.7851V17.898H22.9928V10.723Z"
            fill="#FF025C"
          />
        </g>
        <defs>
          <clipPath id="clip0_2833_30158">
            <rect
              width="17.7434"
              height="16.476"
              fill="white"
              transform="translate(6.39453 6.62305)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
